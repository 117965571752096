







/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import MobileHome from "@/components/home/MobileHome.vue";
import PcHome from "@/components/home/PcHome.vue";
import BasePage from "@/mixins/BasePage";

@Component({
    components: {
        MobileHome, PcHome
    }
})
export default class Home extends BasePage {
    created() {
        super.created();
    }
}
